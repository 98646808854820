import lazyPage from './lazyPage';

const pageMaps = {
  createPost: lazyPage(() => import('../../pages/create-post')),
  home: lazyPage(() => import('../../pages/home')),
  answer: lazyPage(() => import('../../pages/answer')),
  QuestionDetails: lazyPage(() => import('../../pages/question')),
  categories: lazyPage(() => import('../../pages/categories')),
  categoryDetails: lazyPage(() => import('../../pages/category-detail')),
  guidelines: lazyPage(() => import('../../pages/guidelines')),
  login: lazyPage(() => import('../../pages/login')),
  signup: lazyPage(() => import('../../pages/signup')),
  profile: lazyPage(() => import('../../pages/profile')),
  notifications: lazyPage(() => import('../../pages/notifications')),
  search: lazyPage(() => import('../../pages/search')),
  idea: lazyPage(() => import('../../pages/idea')),
  forgotPassword: lazyPage(() => import('../../pages/forgot-password')),
  resetPassword: lazyPage(() => import('../../pages/reset-password')),
  contactUsPage: lazyPage(() => import('../../pages/contact-us'))
};

export default pageMaps;
