import { matchPath } from 'react-router-dom';
import { GetScrollRestorationKeyFunction } from 'react-router-dom';

const SCROLL_RESTORE_PATHS = ['/', '/idea/:ideaSlug', '/question/:questionSlug'];

const customGetKey: GetScrollRestorationKeyFunction = location => {
  const shouldRestoreScroll = SCROLL_RESTORE_PATHS.some(path => !!matchPath(path, location.pathname));
  return shouldRestoreScroll ? location.pathname : location.key;
};

export default customGetKey;
