import { RouteObject, useLoaderData } from 'react-router-dom';
import NProgress from 'nprogress';
import appRoutes from '.';
import AuthRoute from './AuthRoute';
import PublicAndAuthRoute from './PublicAndAuthRoute';
import { ComponentType } from 'react';

export default function getRouteObjectFromRoutes(routes: typeof appRoutes) {
  const routeObjects = Object.values(routes)
    .filter(route => route.component)
    .map(route => {
      const doesRequireAuth = route.auth;
      const doesRequirePublicAndAuth = route.publicAndAuth ?? true;

      let element = <Loader />;
      if (doesRequireAuth) {
        element = <AuthRoute>{element}</AuthRoute>;
      } else if (doesRequirePublicAndAuth) {
        element = <PublicAndAuthRoute>{element}</PublicAndAuthRoute>;
      }

      const routeObject: RouteObject = {
        element,
        path: route.path,
        loader: async () => {
          NProgress.start();
          const defaultComponent = await route.component.preload();
          NProgress.done();
          return defaultComponent;
        }
      };
      return routeObject;
    });
  return routeObjects;
}

function Loader() {
  const { default: Component } = useLoaderData() as { default: ComponentType<unknown> };

  return <Component />;
}
