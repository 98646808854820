import { useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalProps,
  FormControl,
  RadioGroup,
  Radio,
  VStack,
  ModalHeader,
  Box,
  Button,
  RadioGroupProps,
  useToast
} from '@chakra-ui/react';
import useGetReportCategories from '../../services/category/useGetReportCategories';
import { AuthGuardButton } from '../Authentication';
import useReport from '../../services/post/useReport';

type ReportModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  entityId: string;
  onReportComplete?: (promise: Promise<unknown>) => void;
};

function ReportModal(props: ReportModalProps) {
  const { isOpen, onClose, entityId, onReportComplete } = props;
  const { reportCategories, isLoading } = useGetReportCategories();
  const { reportPost, isMutating: isReporting } = useReport();
  const showToast = useToast();
  const [reportCategoryId, setReportCategoryId] = useState<string | null>(null);

  const onChange: RadioGroupProps['onChange'] = nextValue => {
    setReportCategoryId(nextValue as string);
  };

  const onClickSubmit = async () => {
    if (!reportCategoryId || !entityId) return;
    setReportCategoryId(null);
    onClose();

    const reportPromise = reportPost({ entityId, reportCategoryId });
    onReportComplete?.(reportPromise);
    await reportPromise;
    showToast({
      title: 'Reported',
      description: 'Your report has been submitted.',
      status: 'success',
      duration: 3000,
      isClosable: true
    });
  };

  return (
    <Modal
      closeOnEsc
      closeOnOverlayClick
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount
      size={{ base: 'full', md: '2xl' }}
    >
      <ModalOverlay />
      <ModalContent pt="2rem" minHeight="min(45%, 400px)">
        <ModalCloseButton />
        <ModalHeader>Report Post</ModalHeader>
        <ModalBody>
          {isLoading || !reportCategories ? (
            <div>Loading...</div>
          ) : (
            <FormControl as="fieldset">
              <RadioGroup onChange={onChange}>
                <VStack spacing="24px" align="flex-start">
                  {reportCategories.map(reportCategory => (
                    <Radio key={reportCategory.id} value={reportCategory.id}>
                      <Box as="span" fontWeight="bold" fontSize="sm">
                        {reportCategory.name}
                      </Box>
                    </Radio>
                  ))}
                </VStack>
              </RadioGroup>
            </FormControl>
          )}
        </ModalBody>
        <ModalFooter>
          <AuthGuardButton
            variant="solid"
            colorScheme="red"
            mr="4"
            loadingText="Reporting..."
            isLoading={isReporting}
            isDisabled={!reportCategoryId}
            onClick={onClickSubmit}
          >
            Report
          </AuthGuardButton>
          <Button variant="outline" colorScheme="green" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ReportModal;
