import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, ModalProps } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../App/GlobalProviders/AuthProvider';
import Login from './Login';

type AuthModalProps = Pick<ModalProps, 'isOpen' | 'onClose'>;

function AuthModal(props: AuthModalProps) {
  const { isOpen, onClose } = props;
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();

  // NOTE: close modal on success authentication
  useEffect(() => {
    if (!isAuthenticated || !isOpen) return;
    onClose();
  }, [isAuthenticated]);

  useEffect(() => {
    onClose();
  }, [pathname]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'full', md: 'md' }}>
      <ModalOverlay />
      <ModalContent bgColor="rgb(245,245,245)">
        <ModalCloseButton />
        <ModalBody mb={4}>
          <Login isCompact />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default AuthModal;
