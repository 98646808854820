import useSWRMutation from 'swr/mutation';
import getGenericMutationFetcher from '../../utils/swr/genericFetcher';
import { ResponseType } from '../../types';
import useShowAlertOnError from '../../hooks/useShowAlertOnError';

type ApiResponse = ResponseType<boolean>;
type Arg = { entityId: string; reportCategoryId: string };

const reportFetcher = getGenericMutationFetcher<ApiResponse, Arg>();

function useReport() {
  const { trigger, data, error, ...rest } = useSWRMutation<ApiResponse, Error, string, Arg>(
    '/reports/report',
    reportFetcher
  );

  useShowAlertOnError(error, 'Error reporting post', false);

  return {
    reportPost: trigger,
    data: data?.data,
    error,
    ...rest
  };
}

export default useReport;
