import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../components/App/GlobalProviders/AuthProvider';
import getAccessToken from '../localstorage/getAccessToken';

type NonAuthRouteProps = {
  children: ReactNode;
};

function PublicAndAuthRoute(props: NonAuthRouteProps) {
  const { children } = props;
  const { isAuthenticated, isMeLoading } = useAuth();
  const accessToken = getAccessToken();

  // NOTE: neither we are authenticated nor we have token then send the user re-authenticate
  if (!accessToken) {
    return <>{children}</>;
  }

  if (!isMeLoading && isAuthenticated) {
    return <>{children}</>;
  }

  return <Navigate to="/login" replace />;
}

export default PublicAndAuthRoute;
