import { Category } from './Category';

export enum PostTypeEnum {
  IDEA = 'IDEA',
  QUESTION = 'QUESTION',
  RESPONSE = 'RESPONSE'
}

export type PostType = keyof typeof PostTypeEnum;

export interface UserInfo {
  name: string;
  userName: string;
  email: string;
  isMe?: boolean;
  isBlocked?: boolean;
  isDeleted?: boolean;
}

export type Sentiment = 'PRO' | 'CON';

export interface UpvoteAndSentiment {
  sentiment?: Sentiment;
  isUpvoted: boolean;

  //? NOTE: only for type ASSERTION
  isDownvoted?: boolean;
  downvoteCount?: number;

  upvoteCount: number;
  assertUpvoteCount: number;
  assertDownvoteCount: number;
}

export interface Post {
  heading: string;
  _id: string;
  parentInfo: { _id: string; heading: string } | null;
  type: PostTypeEnum;
  upvoteCount: number;
  downvoteCount: number;
  isUpvoted: boolean;
  isDownvoted: boolean;
  responderInfo?: UserInfo;
  responseCreatedAt?: string;
  userInfo: UserInfo;
  createdAt: string;
  tags: Array<Category>;
  isReported: boolean;
  isFollowed: boolean;
  sentiment: Sentiment;
}
