export type User = {
  _id: string;
  name: string;
  email: string;
  userName: string;
  password: string;
  createdAt: string;
  canReport: boolean;
  isBlocked?: boolean;
  isDeleted?: boolean;
};

export function isUser(user: Record<string, string | boolean>): user is User {
  return (
    user &&
    typeof user.name === 'string' &&
    typeof user.email === 'string' &&
    typeof user._id === 'string' &&
    typeof user.userName === 'string'
  );
}

export function isPartialUser(user: Record<string, string>): user is Pick<User, 'userName' | 'name' | 'email'> {
  return user && typeof user.name === 'string' && typeof user.email === 'string' && typeof user.userName === 'string';
}
