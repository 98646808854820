import useSWRInfinite from 'swr/infinite';
import { OrderBy } from '../../components/HomePostFilter/types';
import useShowAlertOnError from '../../hooks/useShowAlertOnError';
import { AssertionMeta, IErrorResponse, Question, ResponseType } from '../../types';
import getGenericGetKey from '../../utils/swr/getGenericGetKey';

export const getHomePostsGetKey = (filter: OrderBy) => getGenericGetKey(`/home/get-posts?filter=${filter}`);

export type ApiResponse = ResponseType<Array<Question & { assertions: AssertionMeta[] }>>;

function useGetHomePosts(filter: OrderBy) {
  const { data, error, ...rest } = useSWRInfinite<ApiResponse, IErrorResponse>(getHomePostsGetKey(filter));

  useShowAlertOnError(error, "Couldn't get posts");

  // if data has loaded but there is no data, it means there are no posts
  const hasNoData = data?.[data.length - 1]?.data?.length === 0;

  return {
    pageData: data,
    error,
    hasNoData,
    ...rest
  };
}

export default useGetHomePosts;
