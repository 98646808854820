import { ButtonProps, Button, Tooltip } from '@chakra-ui/react';
import { useAuth } from '../App/GlobalProviders/AuthProvider';
import { useAuthPopup } from '../App/GlobalProviders/LoginProvider';
import { MouseEventHandler } from 'react';

type AuthGuardButtonProps = ButtonProps;

function AuthGuradButton(props: AuthGuardButtonProps) {
  const { onClick, 'aria-label': label } = props;
  const { isAuthenticated } = useAuth();
  const { onOpen } = useAuthPopup();

  const authGuardOnClick: MouseEventHandler<HTMLButtonElement> = e => {
    if (!isAuthenticated) return onOpen();

    onClick?.(e);
  };

  const buttonNode = <Button {...props} onClick={authGuardOnClick}></Button>;

  if (!label) return buttonNode;

  return (
    <Tooltip label={label} hasArrow>
      {buttonNode}
    </Tooltip>
  );
}

export default AuthGuradButton;
