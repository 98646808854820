import { ResponseType, User } from '../../types';
import useSWR from 'swr';
import getAccessToken from '../../utils/localstorage/getAccessToken';
import { useEffect, useState } from 'react';

type ApiResponse = ResponseType<Omit<User, 'password'>>;

function fetchMeIfAccessTokenExists() {
  return getAccessToken() && '/users/me';
}

function useGetMe() {
  const [isMeLoading, setIsMeLoading] = useState<boolean>(true);

  const { data, error, isLoading, ...rest } = useSWR<ApiResponse, Error>(fetchMeIfAccessTokenExists, {
    shouldRetryOnError: false,
    errorRetryCount: 0,
    errorRetryInterval: 0,
    keepPreviousData: false,
    revalidateOnFocus: false,
    revalidateIfStale: true,
    focusThrottleInterval: 20_000_000_000 // as large as possible focus on revalidate is not required
  });

  useEffect(() => {
    if (!isLoading) setIsMeLoading(false);
  }, [isLoading]);

  return {
    me: data?.data,
    error,
    isMeLoading,
    ...rest
  };
}

export default useGetMe;
