import { useContext as useContextReact, createContext as reactCreateContext } from 'react';

function createContext<T>(defaultValue: T, strict = false): readonly [() => T, React.Provider<T>] {
  {
    const context = reactCreateContext<T>(defaultValue);
    const useContext = () => {
      const c = useContextReact(context);
      if (!c && strict) {
        throw new Error('useContext must be inside a Provider with a value');
      }
      return c;
    };
    return [useContext, context.Provider];
  }
}

export default createContext;
