import { Arguments } from 'swr';

/** all the cache keys which we don't want to clear on global cache clear */
export const IMMUTABLE_KEYS = [
  '/reports/get-report-categories',
  '/home/topic-guidelines',
  '/categories/get-categories',
  '**/get-responses',
  '**/get-questions',
  '**/get-post' // for both /home/get-posts and /post/get-post
];

export const isKeyClearable = (key: Arguments) => {
  if (!key) return true;

  for (const immutableKey of IMMUTABLE_KEYS) {
    const isExactMatched = key === immutableKey;
    const isPartiallyMatched =
      immutableKey.toString().slice(0, 2) === '**' && key.toString().includes(immutableKey.slice(2));

    // Partially matched so unclearable
    if (isPartiallyMatched) return false;

    // bullseye, unclearable
    if (isExactMatched) return false;
  }
};
