import { HStack, Box, Container } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { Navbar } from '../Navbar';
import { useLocation } from 'react-router-dom';
import { isHeaderHiddenForRoute, isSidebarHiddenForRoute } from '../../utils/routes';

import { Header } from '../Header';

function BaseLayout() {
  const { pathname } = useLocation();
  const isHeaderAndSidebarHidden = isHeaderHiddenForRoute(pathname);
  const isSidebarHidden = isSidebarHiddenForRoute(pathname);

  return (
    <Box position="relative">
      {!isHeaderAndSidebarHidden && <Header />}
      <Container pt={!isHeaderAndSidebarHidden ? '1rem' : 0} maxW="6xl" px="0">
        <HStack
          as="main"
          align="start"
          pb={!isHeaderAndSidebarHidden ? '4' : '0'}
          spacing={{ base: 0, md: isHeaderAndSidebarHidden ? 0 : 8 }}
        >
          {!isHeaderAndSidebarHidden && !isSidebarHidden && (
            <Box hideBelow="md" width="14rem" position="sticky" top="4.6rem">
              <Navbar />
            </Box>
          )}
          <Box
            w={!isHeaderAndSidebarHidden && !isSidebarHidden ? 'min(100vw, calc(72rem - 14rem - 2rem))' : 'calc(72rem)'}
          >
            <Outlet />
          </Box>
        </HStack>
      </Container>
    </Box>
  );
}

export default BaseLayout;
