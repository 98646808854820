import { ReactNode, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { SWRConfig, SWRConfiguration } from 'swr';
import getAccessToken from '../../utils/localstorage/getAccessToken';
import { removeAccessToken } from '../../utils/localstorage/removeAccessToken';

const fetcher = async (url: string) => {
  const fullUrl = `${import.meta.env.VITE_SERVICE_URL}${url}`;
  const accessToken = getAccessToken();

  const response = await fetch(fullUrl, {
    headers: {
      Accept: 'application/json',
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {})
    }
  });

  if (!response.ok) {
    const errorInfo = await response.json();
    const error = new Error(errorInfo.message);
    Object.assign(error, { statusCode: response.status });
    if (errorInfo?.status) {
      Object.assign(error, { status: errorInfo.status });
    }
    throw error;
  }

  return response.json();
};

function SWRProvider(props: { children: ReactNode }) {
  const { children } = props;
  const navigate = useNavigate();

  const decoreatedFetcherFunc = async (url: string) => {
    console.log({ url });
    try {
      const res = await fetcher(url);
      return res;
    } catch (error) {
      if (error instanceof Error && 'statusCode' in error) {
        if (error.statusCode === 403) {
          removeAccessToken();
          navigate('/login');
        }
      }
      throw error;
    }
  };

  const swrOption = useMemo<SWRConfiguration>(
    () => ({
      shouldRetryOnError: false,
      fetcher: decoreatedFetcherFunc
    }),
    []
  );

  return <SWRConfig value={swrOption}>{children}</SWRConfig>;
}

export default SWRProvider;
