import useSWRInfinite from 'swr/infinite';
import useShowAlertOnError from '../../hooks/useShowAlertOnError';
import { IErrorResponse, Question, ResponseType } from '../../types';
import getGenericGetKey from '../../utils/swr/getGenericGetKey';

type Response = ResponseType<Array<Question>>;

export const getQuestionsKey = getGenericGetKey('/questions/get-questions');

function useGetQuestions() {
  const { data, error, ...rest } = useSWRInfinite<Response, IErrorResponse>(getQuestionsKey);

  useShowAlertOnError(error, "Couldn't get questions");

  const hasNoData = data?.[data.length - 1]?.data?.length === 0;

  return {
    pageData: data,
    isError: error,
    hasNoData,
    ...rest
  };
}

export default useGetQuestions;
