import { MutationFetcher } from 'swr/mutation';
import getAccessToken from '../localstorage/getAccessToken';

// NOTE: The need for this function is to provide types for the generic mutation fetcher.
const getGenericMutationFetcher = <Data = unknown, Arg = unknown>() => {
  // NOTE: This is a generic mutation fetcher that can be used with any mutation.
  const genericMutationFetcher: MutationFetcher<Data, Arg, string> = async (url, options) => {
    const { arg } = options;
    const fullUrl = `${import.meta.env.VITE_SERVICE_URL}${url}`;
    const token = getAccessToken();

    const headers = new Headers();
    headers.append('Accept', 'application/json');

    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }

    if (arg) {
      headers.append('Content-Type', 'application/json');
    }

    const response = await fetch(fullUrl, {
      method: arg ? 'POST' : 'GET',
      headers: headers,
      ...(arg
        ? {
            body: JSON.stringify(arg)
          }
        : {})
    });

    if (!response.ok) {
      const errorInfo = await response.json();
      throw new Error(errorInfo.message);
    }

    return response.json();
  };

  return genericMutationFetcher;
};

export default getGenericMutationFetcher;
