import useSWR from 'swr';
import useShowAlertOnError from '../../hooks/useShowAlertOnError';
import { Category } from '../../types';
import { ResponseType } from '../../types/ResonseType';

type Response = ResponseType<Array<Category>>;

function useGetCategories() {
  const { data, error, mutate } = useSWR<Response>('/categories/get-categories');

  useShowAlertOnError(error, 'Error in fetching categories');

  return {
    categories: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate
  };
}

export default useGetCategories;
