import { SWRInfiniteKeyLoader } from 'swr/infinite';

const getGenericGetKey: (url: string, onEmptyPreviousData?: () => void) => SWRInfiniteKeyLoader =
  (url, onEmptyPreviousData) => (index, previousPageData) => {
    const [baseUrl, queryParams] = url.split('?');
    const params = new URLSearchParams(queryParams);

    if (previousPageData && !previousPageData.data.length) {
      onEmptyPreviousData?.();
      return null;
    }
    if (index === 0) {
      params.set('page', '1');
      return `${baseUrl}?${params.toString()}`;
    }

    const previousPageLastItem = previousPageData.data.at(-1);
    params.set('page', (index + 1).toString());
    params.set('cursor', previousPageLastItem.cursor ?? previousPageLastItem._id);
    return `${baseUrl}?${params.toString()}`;
  };

export default getGenericGetKey;
