import { type Theme, extendTheme } from '@chakra-ui/react';
import globalStyles from './global';

// NOTE: https://chakra-ui.com/docs/styled-system/theme

const theme: Partial<Theme> = extendTheme({
  // gloal stylesa
  styles: {
    global: globalStyles
  },
  components: {
    Popover: {
      variants: {
        responsive: {
          content: {
            width: 'unset',
            maxWidth: 'unset',
            minWidth: 'unset'
          }
        }
      }
    }
  }
});

export default theme;
