import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Box,
  Image
} from '@chakra-ui/react';
import Navbar from './Navbar';

type SideBarProps = Pick<DrawerProps, 'onClose' | 'isOpen'>;

function SideBar(props: SideBarProps) {
  const { onClose, isOpen } = props;

  const onClickNavbarItem = () => onClose();

  return (
    <Drawer onClose={onClose} isOpen={isOpen} placement="left">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px" p="0" px="2rem">
          <Box>
            <Image src="/logo.png" width="6rem" height="auto" />
          </Box>
        </DrawerHeader>
        <DrawerBody>
          <Navbar onClickNavbarItem={onClickNavbarItem} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default SideBar;
