import { SystemStyleInterpolation } from '@chakra-ui/react';

const globalStyles: SystemStyleInterpolation = {
  body: {
    backgroundColor: 'rgb(245,245,245)'
  },
  '.text-styles,.md-preview': {
    wordBreak: 'break-word',

    'h1,h2,h3,h4,h5,h6': {
      margin: 'calc(1.25rem / 2) 0',
      padding: 0,
      fontWeight: 700,
      lineHeight: 1.25
    },
    h2: {
      fontSize: '1.5rem'
    },
    p: {
      marginBottom: '1.5rem',
      fontSize: { base: '16px', md: '18px' }
    },
    a: {
      color: 'rgb(0, 102, 204)',
      textDecoration: 'underline'
    }
  },
  '.text-styles.text-sm': {
    wordBreak: 'break-word',

    h2: {
      fontSize: '1.1rem'
    },
    p: {
      fontSize: { base: '14px', md: '16px' }
    },
    a: {
      color: 'rgb(0, 102, 204)',
      textDecoration: 'underline'
    },
    'h1,h2,h3,h4,h5,h6': {
      margin: 'calc(1.25rem / 2) 0',
      padding: 0,
      fontWeight: 700,
      lineHeight: 1.25
    }
  }
};

export default globalStyles;
