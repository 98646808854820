import { useMemo } from 'react';
import { Box, Link, Image, BoxProps } from '@chakra-ui/react';
import { Link as RLink } from 'react-router-dom';

type LogoProps = Pick<BoxProps, 'width' | 'height'> & {
  asLink?: boolean;
};

export function Logo(props: LogoProps) {
  const { width = '6rem', height = 'auto', asLink = false } = props;

  const imageComponent = useMemo(
    () => (
      <Box>
        <Image src="/logo.png" width={width} height={height} alt="Wikicheck" />
      </Box>
    ),
    []
  );

  if (asLink) {
    return (
      <Link as={RLink} to="/">
        {imageComponent}
      </Link>
    );
  }

  return imageComponent;
}
