import useSWRImmutable from 'swr/immutable';
import { ResponseType } from '../../types';

type ApiResponse = ResponseType<Array<{ name: string; id: string }>>;

function useGetReportCategories() {
  const { data, error, isLoading, ...rest } = useSWRImmutable<ApiResponse, Error, string>(
    '/reports/get-report-categories'
  );

  return {
    reportCategories: data?.data,
    error,
    isLoading,
    ...rest
  };
}

export default useGetReportCategories;
