import { HStack, Image, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import Select, { GroupBase, OptionProps, Props as SelectProps, components } from 'react-select';
import useGetCategories from '../../services/category/useGetCategories';

const { Option } = components;

type TOption = {
  value: string;
  label: string;
  iconUrl: string;
};

type TagSelect = Pick<SelectProps<TOption, false, GroupBase<TOption>>, 'placeholder' | 'onFocus' | 'isDisabled'> &
  Pick<ControllerProps, 'defaultValue' | 'name' | 'rules'>;

function TagSelect(props: TagSelect) {
  const { name, rules, defaultValue, ...rest } = props;
  const { control } = useFormContext();
  const { categories, isError, isLoading } = useGetCategories();

  const options = useMemo(() => {
    if (isError || isLoading) return [];

    return categories?.map(category => ({
      value: category.id,
      label: category.name,
      iconUrl: category.iconUrl
    }));
  }, [categories]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field }) => (
        <StyledSelect
          {...field}
          {...rest}
          components={{ Option: IconOption }}
          placeholder={props.placeholder}
          onFocus={props.onFocus}
          options={options}
          isClearable
          classNamePrefix="react-select"
        />
      )}
    />
  );
}

function IconOption(props: OptionProps) {
  const { iconUrl, label } = props.data as TOption;

  return (
    <Option {...props}>
      <HStack>
        {iconUrl && <Image src={iconUrl} alt={label} width="20px" height="20px" />}
        <Text>{label}</Text>
      </HStack>
    </Option>
  );
}

export const StyledSelect = styled(Select)`
  flex: 1;
  & .react-select__placeholder {
    font-size: 1rem;
  }
`;

export default TagSelect;
