import { useState, useDeferredValue, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Icon,
  IconButtonProps,
  Tooltip,
  Portal,
  Box,
  Fade,
  Card,
  Spinner,
  useOutsideClick,
  HStack,
  Avatar,
  VStack,
  Text
} from '@chakra-ui/react';
import { ReactComponent as Doc } from '../../svgs/doc.svg';
import { ReactComponent as Magnifier } from '../../svgs/magnifier.svg';
import useGetSearch, { Match } from '../../services/search/useGetSearch';
import { Post, PostTypeEnum, User } from '../../types';
import slugifyEntity from '../../utils/url/slugifyEntity';

export function SearchBar() {
  const [isSearchInputFocussed, setIsSearchInputFocussed] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const [searchQuery, setSearchQuery] = useState('');
  const deferredSearchQuery = useDeferredValue(searchQuery.trim());
  const { search, matches, isSearching, reset } = useGetSearch();

  useOutsideClick({
    ref: containerRef,
    handler: () => setIsSearchInputFocussed(false)
  });

  // NOTE: deferred search on update of deferred seach query
  useEffect(() => {
    if (deferredSearchQuery) return void search(deferredSearchQuery);
    reset();
  }, [deferredSearchQuery]);

  // NOTE: on focus of search input make an initial request to get search results
  useEffect(() => {
    if (isSearchInputFocussed && deferredSearchQuery) search(searchQuery);
  }, [isSearchInputFocussed]);

  const onFocus = () => setIsSearchInputFocussed(true);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(event.target.value);

  return (
    <Box w={isSearchInputFocussed ? '62%' : '26rem'} height="auto" transition="width 0.25s" ref={containerRef}>
      <InputGroup position="relative">
        <InputRightElement>
          {isSearching ? (
            <Spinner size="sm" />
          ) : (
            <IconButton icon={<Magnifier />} aria-label="Search" variant="ghost" />
          )}
        </InputRightElement>
        <Input
          type="text"
          placeholder="Search any topics..."
          value={searchQuery}
          _placeholder={{ color: 'gray.400' }}
          onChange={onChange}
          onFocus={onFocus}
        />
        {isSearchInputFocussed && (
          <Box position="absolute" top="2.6rem" left="0" width="100%" overflow="auto" maxHeight="400px">
            <Card width="100%" overflow="clip">
              {matches &&
                matches.length > 0 &&
                matches.map(match => (
                  <MatchResult
                    key={match.item?.type === 'USER' ? match.item.userName : match.item._id}
                    onClick={() => {
                      setIsSearchInputFocussed(false);
                      setSearchQuery('');
                    }}
                    {...match}
                  />
                ))}
              {matches && matches.length === 0 && (
                <Box p="3" borderBottom="1px solid" borderColor="gray.300">
                  <Text fontSize="md">No results found</Text>
                </Box>
              )}
            </Card>
          </Box>
        )}
      </InputGroup>
      <Portal>
        <Fade in={isSearchInputFocussed} unmountOnExit>
          <Box
            position="fixed"
            inset="0 0"
            bg="rgba(0, 0, 0, 0.7)"
            zIndex="1"
            onClick={() => setIsSearchInputFocussed(false)}
          />
        </Fade>
      </Portal>
    </Box>
  );
}

type MatchResultProps = Match & { onClick: () => void };

export function MatchResult(props: MatchResultProps) {
  const { item, onClick } = props;

  const isTypeUser = item.type === 'USER';

  return (
    <Box p="3" borderBottom="1px solid" borderColor="gray.300" onClick={onClick}>
      {isTypeUser ? <UserResult {...item} /> : <EntityResult {...item} />}
    </Box>
  );
}

function UserResult(props: Pick<User, 'userName' | 'name' | 'email'>) {
  const { userName, name, email } = props;

  const url = `/profile/${userName}`;

  return (
    <HStack as={Link} to={url}>
      <Avatar name={name} size="sm" />
      <VStack align="flex-start" spacing={0}>
        <Text fontSize="md" fontWeight="bold">
          {name}
        </Text>
        <Text fontSize="2xs" color="gray.600">
          {email}
        </Text>
        <Text fontSize="2xs" color="gray.600">
          {userName}
        </Text>
      </VStack>
    </HStack>
  );
}

function EntityResult(props: Pick<Post, 'heading' | '_id' | 'type'>) {
  const { heading, _id, type } = props;
  const isQuestion = type === PostTypeEnum.QUESTION;

  const slug = slugifyEntity(_id, heading);
  const url = isQuestion ? `/question/${slug}` : `/idea/${slug}`;

  return (
    <HStack as={Link} to={url} alignItems="center" spacing={1}>
      <Icon as={Doc} width="1.8rem" height="auto" mt="0.5rem" />
      <VStack align="flex-start" spacing="0">
        <Text fontSize="md">{heading}</Text>
      </VStack>
    </HStack>
  );
}

export function SearchBarIconButton(props: Omit<IconButtonProps, 'aria-label'>) {
  return (
    <Tooltip label="Search">
      <IconButton
        icon={<Magnifier />}
        fontSize="2xl"
        as={Link}
        variant="link"
        {...props}
        aria-label="Search"
        to="/search"
      ></IconButton>
    </Tooltip>
  );
}
