import { Button, ButtonProps, VStack, Icon } from '@chakra-ui/react';
import { ReactComponent as Home } from '../../svgs/home.svg';
import { ReactComponent as ParallelTasks } from '../../svgs/parallel-tasks.svg';
import { ReactComponent as Rules } from '../../svgs/rules.svg';
import { ReactComponent as Answers } from '../../svgs/answers.svg';
import { ReactComponent as Assistant } from '../../svgs/assisant.svg';
import { ReactComponent as YoutubeIcon } from '../../svgs/youtube.svg';
import { ReactNode } from 'react';
import { NavLinkProps, NavLink, useLocation } from 'react-router-dom';
import { useHowToVideo } from '../App/GlobalProviders/HowToVideoProvider';

type NavbarProps = { onClickNavbarItem?: () => void };

function Navbar(props: NavbarProps) {
  const { onClickNavbarItem } = props;
  const { onOpen: onOpenHowToVideo } = useHowToVideo();

  return (
    <VStack
      as="nav"
      gap={2}
      width="100%"
      align="left"
      __css={{
        '.chakra-button__icon': {
          fontSize: '2xl'
        }
      }}
    >
      <NavBarItem leftIcon={<Home />} to="/" onClick={onClickNavbarItem}>
        Home
      </NavBarItem>
      <NavBarItem leftIcon={<ParallelTasks />} to="/categories" onClick={onClickNavbarItem}>
        Categories
      </NavBarItem>
      <NavBarItem leftIcon={<Answers />} to="/answer" onClick={onClickNavbarItem}>
        Answer
      </NavBarItem>
      <NavBarItem leftIcon={<Rules />} to="/guidelines" onClick={onClickNavbarItem}>
        Guidelines
      </NavBarItem>
      <NavBarItem
        leftIcon={<Icon as={YoutubeIcon} color="red"></Icon>}
        onClick={() => {
          onClickNavbarItem?.();
          onOpenHowToVideo?.();
        }}
      >
        How to
      </NavBarItem>
      <NavBarItem leftIcon={<Assistant />} to="/contact-us" onClick={onClickNavbarItem}>
        Contact Us
      </NavBarItem>
    </VStack>
  );
}

type NavBarItemProps = Pick<ButtonProps, 'leftIcon' | 'onClick'> & Partial<Pick<NavLinkProps, 'to'>>;

function NavBarItem(props: NavBarItemProps & { children: ReactNode }) {
  const { children, leftIcon, to, onClick } = props;

  const { pathname } = useLocation();
  const isCurrentPath = pathname === to;

  return (
    <Button
      {...(to ? { as: NavLink } : {})}
      variant="link"
      width="100%"
      _hover={{ bg: 'green.100', color: 'black', textDecor: 'underline' }}
      color="black"
      bg={isCurrentPath ? 'gray.200' : 'transparent'}
      fontWeight="normal"
      justifyContent="flex-start"
      fontSize="14px"
      leftIcon={leftIcon}
      p="8px 16px"
      to={to}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export default Navbar;
