import useSWR from 'swr';
import { ResponseType, IErrorResponse } from '../../types';
import useShowAlertOnError from '../../hooks/useShowAlertOnError';
import { useAuth } from '../../components/App/GlobalProviders/AuthProvider';

type ApiResponse = ResponseType<number>;

function useGetUnreadNotificationCount() {
  const { isAuthenticated } = useAuth();

  const { data, error, ...rest } = useSWR<ApiResponse, IErrorResponse>(
    isAuthenticated ? '/notifications/get-unread-count' : null,
    {
      refreshInterval: 4000
    }
  );

  useShowAlertOnError(error, 'Error fetching notifications count');

  return {
    unreadNotificationsCount: data?.data,
    error,
    ...rest
  };
}

export default useGetUnreadNotificationCount;
