import { UseDisclosureReturn, useDisclosure, Portal } from '@chakra-ui/react';
import createContext from '../../../utils/context/createContext';
import { AuthModal } from '../../Authentication';

type AuthContextType = Pick<UseDisclosureReturn, 'isOpen' | 'onOpen' | 'onClose'>;

type AuthProviderProps = {
  children: React.ReactNode;
};

const [useAuthPopup, Provider] = createContext<AuthContextType>({
  isOpen: false,
  onOpen: () => ({}),
  onClose: () => ({})
});

function LoginProvider(props: AuthProviderProps) {
  const { children } = props;
  const disclosureState = useDisclosure();

  return (
    <Provider value={disclosureState}>
      {children}
      <Portal>
        <AuthModal isOpen={disclosureState.isOpen} onClose={disclosureState.onClose} />
      </Portal>
    </Provider>
  );
}

export { useAuthPopup, LoginProvider };
