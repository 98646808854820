import SWRProvider from '../SwrProvider';
import { AuthProvider } from './AuthProvider';
import { ReactNode } from 'react';
import { LoginProvider } from './LoginProvider';
import { ReportProvider } from './ReportProvider';
import { NotificationProvider } from './NotificationProvider';
import { HowToVideoProvider } from './HowToVideoProvider';

type GlobalProvidersProps = {
  children: ReactNode;
};

function GlobalProviders(props: GlobalProvidersProps) {
  const { children } = props;

  return (
    <SWRProvider>
      <AuthProvider>
        <HowToVideoProvider>
          <LoginProvider>
            <NotificationProvider>
              <ReportProvider>{children}</ReportProvider>
            </NotificationProvider>
          </LoginProvider>
        </HowToVideoProvider>
      </AuthProvider>
    </SWRProvider>
  );
}

export default GlobalProviders;
