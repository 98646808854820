import {
  Box,
  ButtonProps,
  Container,
  Flex,
  HStack,
  IconButton,
  Portal,
  Show,
  Spacer,
  useDisclosure
} from '@chakra-ui/react';
import { MouseEventHandler } from 'react';
import { ReactComponent as Askubuntu } from '../../svgs/ask-ubuntu.svg';
import { ReactComponent as Bulb } from '../../svgs/bulb.svg';
import { ReactComponent as Menu } from '../../svgs/menu.svg';
import { LinkProps, useNavigate } from 'react-router-dom';
import { useAuth } from '../App/GlobalProviders/AuthProvider';
import AskQuestionModal from '../AskQuestionModal/AskQuestionModal';
import { AuthGuardButton } from '../Authentication';
import { SideBar } from '../Navbar';
import AuthenticatedAvatar from './AuthenticatedAvatar';
import { Logo } from './Logo';
import NotificationButton from './NotificationButton';
import { SearchBar, SearchBarIconButton } from './SearchBar';

function Header() {
  const { isAuthenticated } = useAuth();
  const { isOpen: isAskModalOpen, onClose: onAskModalClose, onOpen: onAskModalOpen } = useDisclosure();
  const { isOpen: isSidebarOpen, onClose: onSidebarClose, onOpen: onSidebarOpen } = useDisclosure();

  return (
    <Box
      as="header"
      bg="white"
      boxShadow={{ base: 'lg', md: 'md' }}
      position="sticky"
      top="0"
      left="0"
      zIndex="docked"
      paddingY={{ base: '8px', md: '0' }}
    >
      <Container maxWidth="6xl" paddingX={{ base: '8px', md: '0' }}>
        <Flex align="center" gap={{ base: '0.8', md: '3' }}>
          <HStack>
            <IconButton
              icon={<Menu />}
              fontSize="2xl"
              variant="link"
              aria-label="sidebar"
              onClick={onSidebarOpen}
              hideFrom="md"
            ></IconButton>
            <Logo asLink />
          </HStack>
          {/** Show this only on larger screen */}
          <Show above="md">
            <SearchBar />
            <Spacer />
            <HeaderButton onClick={onAskModalOpen} aria-label="Post a question">
              <Askubuntu />
            </HeaderButton>
            <HeaderButton to="/new" aria-label="Post an idea">
              <Bulb />
            </HeaderButton>
            <NotificationButton />
          </Show>
          {/** Show this only on smaller screen */}
          <Show below="md">
            <Spacer />
            <SearchBarIconButton />
            {isAuthenticated && (
              <>
                <HeaderButton onClick={onAskModalOpen} aria-label="Post a question">
                  <Askubuntu />
                </HeaderButton>
                <HeaderButton to="/new" aria-label="Post an idea">
                  <Bulb />
                </HeaderButton>
                <Box mr={2}>
                  <NotificationButton />
                </Box>
              </>
            )}
          </Show>
          <AuthenticatedAvatar />
        </Flex>
      </Container>
      <Portal>
        <AskQuestionModal isOpen={isAskModalOpen} onClose={onAskModalClose} />
        <SideBar isOpen={isSidebarOpen} onClose={onSidebarClose} />
      </Portal>
    </Box>
  );
}

type HeaderButtonProps = Pick<ButtonProps, 'rightIcon' | 'children' | 'onClick' | 'aria-label'> &
  Pick<Partial<LinkProps>, 'to'>;

function HeaderButton(props: HeaderButtonProps) {
  const { children, rightIcon, onClick, to, 'aria-label': label } = props;
  const navigate = useNavigate();

  const navigateOnClick: MouseEventHandler<HTMLButtonElement> = e => {
    if (to) return navigate(to);

    onClick?.(e);
  };

  return (
    <AuthGuardButton
      aria-label={label}
      variant="unstyled"
      fontWeight="light"
      fontSize="2xl"
      px="1.5"
      rightIcon={rightIcon}
      display="inline-flex"
      color="gray.500"
      _hover={{
        backgroundColor: 'gray.100',
        color: 'gray.800'
      }}
      onClick={navigateOnClick}
    >
      {children}
    </AuthGuardButton>
  );
}

export default Header;
