import { usePrevious } from '@chakra-ui/react';
import { ReactNode, useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

type ResetWrapperProps = Pick<FallbackProps, 'resetErrorBoundary'> & { children: ReactNode };

function ResetWrapper(props: ResetWrapperProps) {
  const { resetErrorBoundary: reset, children } = props;
  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname);

  useEffect(() => {
    if (prevPathname !== undefined && prevPathname !== pathname) {
      reset();
    }
  }, [pathname, prevPathname]);

  return <>{children}</>;
}

export default ResetWrapper;
