import { useEffect, useState } from 'react';
import { UseDisclosureReturn, useDisclosure, Portal } from '@chakra-ui/react';
import createContext from '../../../utils/context/createContext';
import { ReportModal } from '../../Report';

type ReportContextType = Pick<UseDisclosureReturn, 'isOpen'> & {
  report: (entityId: string, onReportComplete?: (promise: Promise<unknown>) => void) => void;
  closeReport: () => void;
};

type ReportProviderProps = {
  children: React.ReactNode;
};

const [useReport, Provider] = createContext<ReportContextType>({
  report: () => ({}),
  isOpen: false,
  closeReport: () => ({})
});

function ReportProvider(props: ReportProviderProps) {
  const { children } = props;
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [onReportComplete, setOnReportComplete] = useState<(proimse: Promise<unknown>) => void>();
  const [entityId, setEntityId] = useState<string>('');

  useEffect(() => {
    if (entityId) return onOpen();
  }, [entityId]);

  const report = (entityId: string, onReportComplete?: (promise: Promise<unknown>) => void) => {
    if (!entityId) throw new Error('entityId is not provided');

    setOnReportComplete(() => onReportComplete);
    setEntityId(entityId);
  };

  const closeReport = () => {
    setEntityId('');
    onClose();
  };

  return (
    <Provider value={{ ...{ report, closeReport, isOpen } }}>
      {children}
      <Portal>
        <ReportModal isOpen={isOpen} onClose={closeReport} entityId={entityId} onReportComplete={onReportComplete} />
      </Portal>
    </Provider>
  );
}

export { useReport, ReportProvider };
