import useSWRMutation from 'swr/mutation';
import { ResponseType, Post, User } from '../../types';
import useShowAlertOnError from '../../hooks/useShowAlertOnError';

// Denotes the start/end indices of a match
//                 start    end
//                   ↓       ↓
type RangeTuple = [number, number];

export type FuseResultMatch = {
  indices: ReadonlyArray<RangeTuple>;
  key?: string;
  refIndex?: number;
  value?: string;
};

export type FuseResult<T> = {
  item: T;
  refIndex: number;
  score?: number;
  matches?: ReadonlyArray<FuseResultMatch>;
};

export type Match = FuseResult<
  (Pick<User, 'userName' | 'email' | 'name'> & { type: 'USER' }) | Pick<Post, 'heading' | '_id' | 'type'>
>;
type ApiResponse = ResponseType<Array<Match>>;
type Arg = string;

const searchFetcher = async (url: string, option: { arg: Arg }) => {
  const { arg: query } = option;
  const fullUrl = `${import.meta.env.VITE_SERVICE_URL}${url}?query=${query}`;
  const response = await fetch(fullUrl);

  if (!response.ok) {
    throw new Error('An error occurred while searching');
  }

  return response.json() as Promise<ApiResponse>;
};

function useGetSearch() {
  const { trigger, error, isMutating, data, ...rest } = useSWRMutation<ApiResponse, Error, string, Arg>(
    `/search/`,
    searchFetcher
  );

  useShowAlertOnError(error, 'Error while searching', false);

  return {
    search: trigger,
    matches: data?.data,
    isSearching: isMutating,
    error,
    ...rest
  };
}

export default useGetSearch;
