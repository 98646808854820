import type { PreloadableComponent } from '../pages/lazyPage';
import pageMaps from '../pages/pageMaps';

export type RouteConfig = {
  path: string;
  icon?: React.ReactNode;
  title?: string;
  component: PreloadableComponent;
  auth?: boolean;
  publicAndAuth?: boolean;
};

function asAppRoute<T>(obj: { [K in keyof T]: RouteConfig }) {
  return obj;
}

const appRoutes = asAppRoute({
  new: {
    path: '/new',
    component: pageMaps.createPost,
    auth: true
  },
  home: {
    path: '/',
    component: pageMaps.home
  },
  answer: {
    path: '/answer',
    component: pageMaps.answer
  },
  questionDetails: {
    path: '/question/:questionSlug',
    component: pageMaps.QuestionDetails
  },
  categories: {
    path: '/categories',
    component: pageMaps.categories
  },
  categoryDetails: {
    path: '/category/:categorySlug',
    component: pageMaps.categoryDetails
  },
  guidelines: {
    path: '/guidelines',
    component: pageMaps.guidelines
  },
  login: {
    path: '/login',
    component: pageMaps.login,
    publicAndAuth: false
  },
  signup: {
    path: '/signup',
    component: pageMaps.signup,
    publicAndAuth: false
  },
  profile: {
    path: '/profile/:username',
    component: pageMaps.profile
  },
  notifications: {
    path: '/notifications',
    component: pageMaps.notifications
  },
  search: {
    path: '/search',
    component: pageMaps.search
  },
  idea: {
    path: '/idea/:ideaSlug',
    component: pageMaps.idea
  },
  forgotPassword: {
    path: '/forgot-password',
    component: pageMaps.forgotPassword,
    publicAndAuth: false
  },
  resetPassword: {
    path: '/reset-password',
    component: pageMaps.resetPassword
  },
  contactUsPage: {
    path: '/contact-us',
    component: pageMaps.contactUsPage
  }
});

export default appRoutes;

const HIDDEN_HEADER_ROUTES = ['/new', '/login', '/signup', '/forgot-password', '/reset-password'];
const HIDDEN_SIDEBAR_ROUTES = ['/notifications'];

export function isHeaderHiddenForRoute(route: string) {
  return HIDDEN_HEADER_ROUTES.includes(route);
}

export function isSidebarHiddenForRoute(route: string) {
  return HIDDEN_SIDEBAR_ROUTES.includes(route);
}
