import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalProps,
  useToast
} from '@chakra-ui/react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSWRConfig } from 'swr';
import { unstable_serialize } from 'swr/infinite';
import usePostQuestion from '../../services/question/usePostQuestion';
import { getHomePostsGetKey } from '../../services/home/useGetHomePosts';
import { getQuestionsKey } from '../../services/question/useGetQuestions';
import TextArea from '../CreatePostCard/TextArea';
import TagSelect from '../Tag/TagSelect';
import getGenericGetKey from '../../utils/swr/getGenericGetKey';
import { useAuth } from '../App/GlobalProviders/AuthProvider';

type AskQuestionModalProps = Pick<ModalProps, 'isOpen' | 'onClose'>;

function AskQuestionModal(props: AskQuestionModalProps) {
  const { mutate } = useSWRConfig();
  const { isMutating, postQuestion } = usePostQuestion();
  const showToast = useToast();
  const { isOpen, onClose } = props;
  const { user } = useAuth();

  const form = useForm<{ question: string; tags: { value: string; label: string } }>({ mode: 'onSubmit' });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = form;

  const onSubmit: SubmitHandler<{ question: string; tags: { value: string; label: string } }> = async ({
    question: heading,
    tags
  }) => {
    await postQuestion({ heading, tags: [tags.value] });
    showToast({
      title: 'Question posted successfully',
      status: 'success',
      duration: 3000
    });

    // NOTE: cache invalidate on answers page
    mutate(unstable_serialize(getQuestionsKey));
    // NOTE: cache invalidate on home page
    mutate(unstable_serialize(getHomePostsGetKey('latest')));
    // NOTE: cache invalidate on target category page
    mutate(unstable_serialize(getGenericGetKey(`/categories/${tags.value}/posts`)));
    // NOTE: cache invalidate on user profile page tab=questions
    mutate(unstable_serialize(getGenericGetKey(`/users/${user?.userName}/list?type=questions`)));

    onClose();
  };

  const resetForm = () => {
    reset();
  };

  return (
    <Modal
      closeOnEsc
      closeOnOverlayClick
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount
      size={{ base: 'full', md: '2xl' }}
      onCloseComplete={resetForm}
    >
      <ModalOverlay />
      <ModalContent pt="2rem" minHeight="min(45%, 400px)">
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...form}>
            <FormControl isInvalid={!!errors.question}>
              <FormLabel>What&apos;s your question ?</FormLabel>
              <TagSelect
                name="tags"
                placeholder="Select Category..."
                rules={{ required: { value: true, message: 'Category is required' } }}
              />
              {errors.tags && <FormErrorMessage>{errors.tags.message}</FormErrorMessage>}
              <TextArea
                {...register('question', {
                  required: 'Question is required',
                  minLength: { value: 20, message: 'Question must have at least 20 characters' },
                  maxLength: { value: 200, message: 'Question must be less than 200 characters' }
                })}
                isDisabled={isMutating}
                resize="none"
                variant="unstyled"
                placeholder="Type your question here..."
                fontSize={{ base: '2xl', md: '4xl' }}
                rows={1}
                fontWeight="extrabold"
                maxLength={200}
              />
              {!errors.question ? (
                <FormHelperText>Your question will be public and other users will be able to answer it.</FormHelperText>
              ) : (
                <FormErrorMessage>{errors.question.message}</FormErrorMessage>
              )}
            </FormControl>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="solid"
            mr={3}
            colorScheme="green"
            onClick={handleSubmit(onSubmit)}
            loadingText="Submitting..."
            isLoading={isMutating}
          >
            Submit
          </Button>
          <Button variant="outline" mr={3} onClick={onClose} colorScheme="red">
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AskQuestionModal;
