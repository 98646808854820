import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../components/App/GlobalProviders/AuthProvider';
import getAccessToken from '../localstorage/getAccessToken';

type AuthRouteProps = {
  children: ReactNode;
};

function AuthRoute(props: AuthRouteProps) {
  const { children } = props;
  const { isAuthenticated } = useAuth();
  const accessToken = getAccessToken();

  if (isAuthenticated) return <>{children}</>;

  // NOTE: neither we are authenticated nor we have token then send the user re-authenticate
  if (!accessToken) {
    return <Navigate to="/login" replace />;
  }

  // NOTE: in case when we have the accessToken but isAuthenticated is false
  // then there may be a me request on going so bare it.
  return <>{children}</>;
}

export default AuthRoute;
