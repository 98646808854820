import useSWRMutation from 'swr/mutation';
import useShowAlertOnError from '../../hooks/useShowAlertOnError';
import { Question, ResponseType } from '../../types';
import getGenericMutationFetcher from '../../utils/swr/genericFetcher';

type Response = ResponseType<{ questionId: string }>;

type Arg = Pick<Question, 'heading'> & { tags: Array<string> };

const createQuestionFetcher = getGenericMutationFetcher<Response, Arg>();

function usePostQuestion() {
  const {
    error,
    trigger: postQuestion,
    ...rest
  } = useSWRMutation<Response, Error, string, Arg>('/questions/add-question', createQuestionFetcher);

  useShowAlertOnError(error, 'Failed to post question');

  return { postQuestion, error, ...rest };
}

export default usePostQuestion;
