import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  useMediaQuery
} from '@chakra-ui/react';
import Youtube, { YouTubeProps } from 'react-youtube';

function LearnMoreModal(props: Omit<ModalProps, 'children'>) {
  const { isOpen, onClose } = props;

  const [isNotMobile] = useMediaQuery('(min-width: 800px)');

  const PLAYER_OPTS: YouTubeProps['opts'] = {
    width: isNotMobile ? '650px' : '100%',
    height: isNotMobile ? '500px' : '650px'
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent minWidth={{ base: '100%', md: '650px' }}>
        <ModalHeader>Learn more about Wikicheck</ModalHeader>
        <ModalCloseButton />
        <ModalBody p="0" m="0">
          <Youtube opts={PLAYER_OPTS} videoId="rpz_IGAeLOs" />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default LearnMoreModal;
