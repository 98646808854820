import { useReducer, useEffect } from 'react';
import { User, isUser, isPartialUser } from '../../../types';
import createContext from '../../../utils/context/createContext';
import useGetMe from '../../../services/authentication/useGetMe';
import getAccessToken from '../../../utils/localstorage/getAccessToken';
import setAccessToken from '../../../utils/localstorage/setAccessToken';
import { removeAccessToken } from '../../../utils/localstorage/removeAccessToken';
import { useSWRConfig } from 'swr';
import { isKeyClearable } from '../../../utils/swr/immutableKeys';

interface AuthContextType {
  isAuthenticated: boolean;
  isMeLoading: boolean;
  user: Omit<User, 'password'> | null;
  login: (data: Omit<User, 'password'> & { accessToken: string }) => void;
  updateUser: (user: Pick<User, 'name' | 'userName' | 'email'>) => void;
  logout: () => Promise<void>;
}

type AuthProviderProps = {
  children: React.ReactNode;
};

export type AuthReducerAction =
  | { type: 'login'; payload: Omit<User, 'password'> }
  | { type: 'update'; payload: Pick<User, 'name' | 'userName' | 'email'> }
  | { type: 'logout'; payload?: never };

type AuthReducerState = {
  isAuthenticated: boolean;
  user: null | Omit<User, 'password'>;
};

const [useAuth, Provider] = createContext<AuthContextType>({
  isAuthenticated: false,
  isMeLoading: true,
  updateUser: () => void {},
  user: null,
  login: () => void {},
  logout: async () => void {}
});

function authReducer(state: AuthReducerState, action: AuthReducerAction) {
  switch (action.type) {
    case 'login':
      if (!action.payload || !isUser(action.payload)) throw new Error('No user provided');

      return { ...state, isAuthenticated: true, user: action.payload };
    case 'update':
      if (!action.payload || !isPartialUser(action.payload) || !state.user) throw new Error('No updated user provided');

      return {
        ...state,
        user: {
          ...state.user,
          name: action.payload.name ?? state?.user?.name,
          userName: action.payload.userName ?? state?.user?.userName,
          email: action.payload.email ?? state?.user?.email
        }
      };
    case 'logout':
      return { isAuthenticated: false, user: null };
  }
}

function AuthProvider(props: AuthProviderProps) {
  const { children } = props;
  const [authState, dispatch] = useReducer(authReducer, { isAuthenticated: false, user: null });
  const { me, isMeLoading } = useGetMe();
  const { mutate } = useSWRConfig();

  // NOTE: when app re-opens / refreshes and there is an access token in local storage
  // then restore the user from the access token by a me query.
  useEffect(() => {
    const isAccessTokenInLocalStorage = getAccessToken();
    if (isAccessTokenInLocalStorage && me) {
      dispatch({ type: 'login', payload: me });
    }
  }, [me]);

  const updateUser = (user: Pick<User, 'userName' | 'name' | 'email'>) => dispatch({ type: 'update', payload: user });

  const clearStaleCache = () => {
    return mutate(isKeyClearable, undefined, false);
  };

  const login: AuthContextType['login'] = data => {
    const { accessToken, ...user } = data;
    setAccessToken(accessToken);
    dispatch({ type: 'login', payload: user });
  };

  const logout = async () => {
    removeAccessToken();
    dispatch({ type: 'logout' });
    await clearStaleCache();
  };

  return <Provider value={{ ...authState, login, logout, isMeLoading, updateUser }}>{children}</Provider>;
}

export { useAuth, AuthProvider };
