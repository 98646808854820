import { useEffect } from 'react';
import { useToast, UseToastOptions } from '@chakra-ui/react';

export function getErrorToastParamsFromError(error: Error, title?: string) {
  return {
    title: title ?? 'An error occurred.',
    description: error.message,
    duration: 5000,
    status: 'error'
  } satisfies UseToastOptions;
}

function useShowAlertOnError(error: Error | undefined, errorTitle?: string, shouldThrow?: boolean) {
  const toast = useToast();

  useEffect(() => {
    if (!error) return;

    toast(getErrorToastParamsFromError(error, errorTitle));

    if (shouldThrow) {
      throw new Error(errorTitle);
    }
  }, [error]);
}

export default useShowAlertOnError;
