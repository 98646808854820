import createContext from '../../../utils/context/createContext';
import useGetUnreadNotificationsCount from '../../../services/notification/useGetUnreadNotificationsCount';

interface NotificationContextType {
  unreadNotificationsCount: number;
}

type NotificationProviderProps = {
  children: React.ReactNode;
};

const [useNotificationCount, Provider] = createContext<NotificationContextType>({
  unreadNotificationsCount: 0
});

function NotificationProvider(props: NotificationProviderProps) {
  const { children } = props;
  const { unreadNotificationsCount = 0 } = useGetUnreadNotificationsCount();

  return <Provider value={{ unreadNotificationsCount }}>{children}</Provider>;
}

export { useNotificationCount, NotificationProvider };
