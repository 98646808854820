import { forwardRef, ChangeEvent, ForwardedRef, useRef, useEffect, useCallback } from 'react';
import { Textarea as ChakraTextArea, TextareaProps as ChakraTextAreaProps, useMergeRefs } from '@chakra-ui/react';

type TextAreaProps = ChakraTextAreaProps & { discardNewLine?: boolean };

function TextArea(props: TextAreaProps, ref: ForwardedRef<HTMLTextAreaElement>) {
  const { discardNewLine, ...textAreaProps } = props;
  const textareaRef = useRef<HTMLTextAreaElement>();
  const mergeRef = useMergeRefs(ref, textareaRef);

  // This is a hack to make the textarea auto resize on defaultValue populate
  useEffect(() => {
    const target = textareaRef.current;
    if (!target) return;
    onKeyUpIncreaseHeight({ target } as ChangeEvent<HTMLTextAreaElement>);
  }, [textareaRef]);

  const onKeyUpIncreaseHeight = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const target = e.target as HTMLTextAreaElement;
    target.style.height = 'auto';
    target.style.height = `${target.scrollHeight}px`;
  };

  const onKeyDownDiscardEnter = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key !== 'Enter') return;

      if (e.key === 'Enter') e.preventDefault();
    },
    [discardNewLine]
  );

  return (
    <ChakraTextArea
      ref={mergeRef}
      {...textAreaProps}
      onChange={onKeyUpIncreaseHeight}
      {...(discardNewLine && { onKeyDown: onKeyDownDiscardEnter })}
    />
  );
}

export default forwardRef(TextArea);
