import { ReactNode } from 'react';
import { useDisclosure, UseDisclosureProps, Portal } from '@chakra-ui/react';
import createContext from '../../../utils/context/createContext';
import { LearnMoreModal } from '../../LearnMoreModal';

type HowToVideoContextType = Pick<UseDisclosureProps, 'isOpen' | 'onClose' | 'onOpen'>;

const [useHowToVideo, Provider] = createContext<HowToVideoContextType>(
  {
    isOpen: false,
    onClose: () => ({}),
    onOpen: () => ({})
  },
  true
);

function HowToVideoProvider(props: { children: ReactNode }) {
  const { children } = props;
  const howToVideoDisclosure = useDisclosure();

  return (
    <Provider value={howToVideoDisclosure}>
      {children}
      <Portal>
        <LearnMoreModal isOpen={howToVideoDisclosure.isOpen} onClose={howToVideoDisclosure.onClose} />
      </Portal>
    </Provider>
  );
}

export { useHowToVideo, HowToVideoProvider };
