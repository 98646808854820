import { Menu, MenuButton, Avatar, MenuList, MenuItem, Button, HStack, VStack, Box, Divider } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useAuth } from '../App/GlobalProviders/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logout } from '../../svgs/logout.svg';

function AuthenticatedAvatar() {
  const { user, logout, isAuthenticated, isMeLoading } = useAuth();
  const navigate = useNavigate();

  const onClickLogOut = async () => {
    await logout();
    // TODO: find a way to remove all the cache after user logout
    navigate('/login');
  };

  if (isMeLoading) return null;

  if (!isAuthenticated) {
    return (
      <HStack ml={4}>
        <Button as={Link} size={{ md: 'sm', base: 'xs' }} colorScheme="facebook" color="white" to="/signup">
          Sign up
        </Button>
        <Button as={Link} size={{ md: 'sm', base: 'xs' }} variant="ghost" to="/login">
          Log in
        </Button>
      </HStack>
    );
  }

  return (
    <Menu>
      <MenuButton>
        <Avatar name={user?.name} size="sm" />
      </MenuButton>
      <MenuList>
        <MenuItem as={Link} to={`/profile/${user?.userName}`}>
          <HStack>
            <Avatar name={user?.name} size="sm" />
            <VStack justify="center" spacing="0" align="flex-start">
              <Box fontWeight="bold">{user?.name}</Box>
              <Box fontSize="2xs" color="gray.500">
                {user?.userName}
              </Box>
            </VStack>
          </HStack>
        </MenuItem>
        <Divider mb="2" />
        <MenuItem icon={<Logout />} onClick={onClickLogOut}>
          Log out
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default AuthenticatedAvatar;
