import useSWRMutation from 'swr/mutation';
import { ResponseType, User } from '../../types';
import getGenericMutationFetcher from '../../utils/swr/genericFetcher';
import useShowAlertOnError from '../../hooks/useShowAlertOnError';

type Response = ResponseType<{ accessToken: string } & Omit<User, 'password'>>;

type Arg = { userName: string; password: string };

const postLogin = getGenericMutationFetcher<Response, Arg>();

function usePostLogin() {
  const { trigger, error, data, ...rest } = useSWRMutation<Response, Error, string, Arg>(`/users/login`, postLogin);

  useShowAlertOnError(error, 'Login failed', false);

  return {
    postLogin: trigger,
    error,
    data: data?.data,
    ...rest
  };
}

export default usePostLogin;
