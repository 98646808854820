import { ScrollRestoration } from 'react-router-dom';
import customGetKey from '../../utils/routes/customGetKey';
import Pages from './pages';

function App() {
  return (
    <>
      <ScrollRestoration getKey={customGetKey} />
      <Pages />
    </>
  );
}

export default App;

// triggering build
