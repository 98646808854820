import { AuthGuardButton } from '../Authentication';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Bell } from '../../svgs/bell.svg';
import { useNotificationCount } from '../App/GlobalProviders/NotificationProvider';

function NotificationButton() {
  const navigate = useNavigate();

  const { unreadNotificationsCount } = useNotificationCount();

  const isThereUnReadNotifications = unreadNotificationsCount > 0;

  const navigateOnClick = () => {
    navigate('/notifications');
  };

  return (
    <Box position="relative">
      <AuthGuardButton
        aria-label="Notifications"
        variant="unstyled"
        fontWeight="light"
        fontSize="2xl"
        display="inline-flex"
        color="gray.500"
        _hover={{
          backgroundColor: 'gray.100',
          color: 'gray.800'
        }}
        onClick={navigateOnClick}
      >
        <Bell />
      </AuthGuardButton>
      {isThereUnReadNotifications && (
        <Box
          backgroundColor="red.600"
          px="6px"
          py="2px"
          color="white"
          position="absolute"
          rounded="md"
          fontSize="2xs"
          right="0"
          top="0"
        >
          {unreadNotificationsCount}
        </Box>
      )}
    </Box>
  );
}

export default NotificationButton;
