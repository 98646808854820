import {
  Box,
  Button,
  Card,
  CardBody,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  VStack,
  useToast
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, Navigate } from 'react-router-dom';
import usePostLogin from '../../services/authentication/usePostLogin';
import { useAuth } from '../App/GlobalProviders/AuthProvider';
import { Logo } from '../Header/Logo';
import getAccessToken from '../../utils/localstorage/getAccessToken';

interface FormFields {
  username: string;
  password: string;
}

type LoginProps = { isCompact?: boolean };

function Login(props: LoginProps) {
  const { isCompact } = props;
  const showToast = useToast();
  const form = useForm<FormFields>();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = form;
  const { postLogin, isMutating } = usePostLogin();
  const { isAuthenticated, login } = useAuth();

  if (isAuthenticated && !!getAccessToken() && !isCompact) {
    return <Navigate to="/" />;
  }

  const onSubmit: SubmitHandler<FormFields> = async data => {
    const { username: userName, password } = data;
    try {
      const response = await postLogin({ userName, password });

      if (!response) return;

      login(response.data);

      showToast({
        title: 'Logged in successfully',
        status: 'success',
        duration: 3000
      });
    } catch (error) {
      return;
    }
  };

  return (
    <VStack spacing="5" minHeight={props.isCompact ? 'auto' : '100vh'}>
      <VStack spacing={1}>
        <Logo width={{ base: '9rem', md: '12rem' }} />
        <Box fontSize={{ base: 'xl', md: '2xl' }} fontWeight="hairline">
          Sign in to Wikicheck
        </Box>
      </VStack>

      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <Card width={{ base: 'auto', md: '22rem' }}>
          <CardBody>
            <FormControl isInvalid={!!errors.username}>
              <FormLabel>Username or Email </FormLabel>
              <Input {...register('username', { required: true })} isDisabled={isMutating} />
              <FormErrorMessage>Username can&apos;t be empty</FormErrorMessage>
            </FormControl>
            <FormControl mt="4" isInvalid={!!errors.password}>
              <FormLabel>Password </FormLabel>
              <Input type="password" {...register('password', { required: true })} isDisabled={isMutating} />
              <Box as={Link} color="green.400" to="/forgot-password" fontSize="xs">
                Forgot Password
              </Box>
              <FormErrorMessage>Passwrod can&apos;t be empty</FormErrorMessage>
            </FormControl>
            <Button
              type="submit"
              mt="8"
              variant="solid"
              colorScheme="green"
              size="sm"
              width="100%"
              isLoading={isMutating}
              loadingText="Signing In..."
            >
              Sign In
            </Button>
          </CardBody>
        </Card>

        <Card width="100%" mt={4}>
          <CardBody>
            <HStack spacing={1} justifyContent="center" fontSize="xs">
              <Box color="gray.500">Don&apos;t have an account ?</Box>
              <Button as={Link} to="/signup" variant="link" colorScheme="blue" fontSize="xs">
                Sign Up
              </Button>
            </HStack>
          </CardBody>
        </Card>
      </Box>
    </VStack>
  );
}

export default Login;
