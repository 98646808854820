import { Box, Heading, HStack, VStack, useClipboard, Button, Alert, AlertIcon } from '@chakra-ui/react';
import { FallbackProps } from 'react-error-boundary';
import { ReactComponent as OutlineExclamation } from '../../svgs/outline-exclamation.svg';
import ResetWrapper from './ResetWrapper';

function InternalServerError(props: FallbackProps) {
  const { resetErrorBoundary, error } = props;

  const { hasCopied, onCopy, setValue } = useClipboard('', 10000);

  const copyErrorInfo = () => {
    const errorInfo = `Error: ${error?.message}\n\nStacktrace: ${error?.stack}`;
    setValue(errorInfo);
    onCopy();
  };

  return (
    <ResetWrapper resetErrorBoundary={resetErrorBoundary}>
      <VStack width={{ base: '100%', md: '100vw' }} justify="center" height="100vh" spacing="4">
        <HStack alignItems="center" mb="2" spacing="4" color="red.400">
          <Box mt="1.5rem" fontSize={{ base: '7xl', md: '9xl' }}>
            <OutlineExclamation />
          </Box>
          <Heading fontWeight="extrabold" fontSize={{ base: '7xl', md: '9xl' }}>
            500
          </Heading>
        </HStack>
        <Box fontSize="2xl" letterSpacing="wide">
          Internal Server Error
        </Box>
        <Box fontSize="xl" color="gray.500" textAlign="center">
          Error occured while doing some operation. Please try refreshing the page.
        </Box>
        {/** create a button to copy the error message and stacktrace  */}
        {!hasCopied ? (
          <Button variant="outline" mt="8" colorScheme="green" onClick={copyErrorInfo}>
            Copy the error info
          </Button>
        ) : (
          // show an alert that the error info has been copied
          <Alert status="success" mt="8" width="auto">
            <AlertIcon />
            You&apos;ve copied, now send it to the responsible developer to help resolve the issue.
          </Alert>
        )}
      </VStack>
    </ResetWrapper>
  );
}

export default InternalServerError;
