import { lazy, ComponentType, LazyExoticComponent } from 'react';

type LoadRoute<P = unknown> = () => Promise<{ default: ComponentType<P> }>;

export interface PreloadableComponent<P = unknown> extends LazyExoticComponent<ComponentType<P>> {
  preload: LoadRoute<P>;
}

export default function lazyPage<P>(loadRoute: LoadRoute<P>): PreloadableComponent<P> {
  const LazyComponent = lazy(loadRoute);
  return Object.assign(LazyComponent, { preload: loadRoute });
}
