import { ErrorBoundary } from 'react-error-boundary';
import { BaseLayout } from '../BaseLayout';
import { InternalServerError } from '../Boundaries';
import GlobalProviders from './GlobalProviders/GlobalProviders';

function pages() {
  return (
    <ErrorBoundary FallbackComponent={InternalServerError}>
      <GlobalProviders>
        <BaseLayout />
      </GlobalProviders>
    </ErrorBoundary>
  );
}

export default pages;
